import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    sairoboData: {}
  },
  getters: {
    getSairoboState: state => state.sairoboData
  },
  mutations: {
    // 设置数据
    setSairoboState(state, sairoboData) {
      state.sairoboData = sairoboData
    },

    // updateOnlineUser(state, onlineUser) {
    //   state.sairoboData.onlineUser = onlineUser
    // },

    // updateTotalUser(state, totalUser) {
    //   state.sairoboData.totalUser = totalUser
    // }
  },
  actions: {
  },
  modules: {
  }
})
